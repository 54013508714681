exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-accreditations-js": () => import("./../../../src/pages/accreditations.js" /* webpackChunkName: "component---src-pages-accreditations-js" */),
  "component---src-pages-carbon-js": () => import("./../../../src/pages/carbon.js" /* webpackChunkName: "component---src-pages-carbon-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cookie-js": () => import("./../../../src/pages/cookie.js" /* webpackChunkName: "component---src-pages-cookie-js" */),
  "component---src-pages-healthcare-js": () => import("./../../../src/pages/healthcare.js" /* webpackChunkName: "component---src-pages-healthcare-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-modern-js": () => import("./../../../src/pages/modern.js" /* webpackChunkName: "component---src-pages-modern-js" */),
  "component---src-pages-organisation-js": () => import("./../../../src/pages/organisation.js" /* webpackChunkName: "component---src-pages-organisation-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-relocation-js": () => import("./../../../src/pages/relocation.js" /* webpackChunkName: "component---src-pages-relocation-js" */),
  "component---src-pages-tos-js": () => import("./../../../src/pages/tos.js" /* webpackChunkName: "component---src-pages-tos-js" */)
}

